import api from './api'
const URL_BASE = process.env.REACT_APP_API

export const GetTokenApp = async () => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/TokenApp`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: `"${process.env.REACT_APP_KEYAPI}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}

export const LoginUsuario = async (token, value) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/Login`, {
      method: 'POST',
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
      body: `"${value}"`,
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const RecuperarSenha = async (email) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/RecuperarLogin`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(email),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const IsHashValido = async (hash) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/IsHashValido`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(hash),
    })
    const json = await req.json()
    return json
  } catch (error) {
    return undefined
  }
}
export const AltSenha = async (values) => {
  try {
    const req = await fetch(`${URL_BASE}/Usuario/AltSenha`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(values),
    })
    const json = req.json()
    return json
  } catch (error) {
    return undefined
  }
}

//Usuario
export const UsuarioGet = async (filters) => {
  try {
    const req = await api.post('/Usuario/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Usuario/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioGetById = async (Id) => {
  try {
    const req = await api.get(`/Usuario/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioAddFoto = async (formData) => {
  try {
    const req = await api.post('/Usuario/AddFotoPortal', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return req.data
  } catch (error) {
    return undefined
  }
}
export const UsuarioEnviarEmailBoasVindas = async (Id) => {
  try {
    const req = await api.get(`/Usuario/EnviarEmailBoasVindas?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

// Conta
export const ContaAdd = async (values) => {
  try {
    const req = await api.post('/Conta/Add', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaUpdate = async (values) => {
  try {
    const req = await api.post('/Conta/Update', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaGetByFilter = async (filters) => {
  try {
    const req = await api.post('/Conta/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaGetById = async (Id) => {
  try {
    const req = await api.get(`/Conta/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaSendContrato = async (id) => {
  try {
    const req = await api.get(`/Conta/SendContrato?id=${id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaAceite = async (id) => {
  try {
    const req = await fetch(`${URL_BASE}/Conta/AceiteContrato?id=${id}`, {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    })
    const json = await req.json()
    return json
  } catch (error) {
    console.log('error', error)
    return undefined
  }
}
export const ContaCrmTpPerdaGetBy = async (ContaId) => {
  try {
    const req = await api.get(`/Conta/CrmTpPerdaGetByConta?id=${ContaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaCampoAddOrUp = async (values) => {
  try {
    const req = await api.post('/Conta/CampoAddOrUp', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaCamposGetBy = async (ContaId) => {
  try {
    const req = await api.get(`/Conta/CamposGetByConta?id=${ContaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaCrmTpPerdaAddOrUp = async (values) => {
  try {
    const req = await api.post('/Conta/CrmTpPerdaAddOrUp', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaHorarioGetBy = async (ContaId) => {
  try {
    const req = await api.get(`/Conta/HorarioGetByConta?id=${ContaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ContaHorarioAddOrUp = async (values) => {
  try {
    const req = await api.post('/Conta/HorarioAddOrUp', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysParametros
export const SysParametroGet = async () => {
  try {
    const req = await api.get(`/SysParametro/GetAll`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysParametroAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysParametro/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Campanhas
export const CampanhaGet = async (filters) => {
  try {
    const req = await api.post(`/Campanha/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CampanhaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Campanha/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CampanhaGetById = async (Id) => {
  try {
    const req = await api.get(`/Campanha/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CampanhaGetDetails = async (Id) => {
  try {
    const req = await api.get(`/Campanha/GetDetails?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CampanhaGetAcessos = async (filters) => {
  try {
    const req = await api.post(`/Campanha/GetAcessos`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

//ChatFila
export const ChatFilaGet = async (filters) => {
  try {
    const req = await api.post(`/ChatFila/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatFilaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/ChatFila/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatFilaGetById = async (Id) => {
  try {
    const req = await api.get(`/ChatFila/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//ChatBot
export const ChatBotGet = async (filters) => {
  try {
    const req = await api.post(`/ChatBot/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatBotGetById = async (Id) => {
  try {
    const req = await api.get(`/ChatBot/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatBotAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/ChatBot/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//ChatCanal
export const ChatCanalGet = async (filters) => {
  try {
    const req = await api.post(`/ChatCanal/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatCanalAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/ChatCanal/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatCanalGetById = async (Id) => {
  try {
    const req = await api.get(`/ChatCanal/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//ChatConversa
export const ChatConversaGetForSidebar = async (filters) => {
  try {
    const req = await api.post(`/ChatConversa/GetForSidebar`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatConversaGetMensagemByFilter = async (filters) => {
  try {
    const req = await api.post(`/ChatConversa/MensagemGetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatConversaMensagemEnviar = async (values) => {
  try {
    const req = await api.post(`/ChatConversa/MensagemEnviar`, values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatConversaMensagemSendFile = async (formData) => {
  try {
    const req = await api.post('/ChatConversa/SendFile', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return req.data
  } catch (error) {
    return undefined
  }
}

export const ChatConversaTransferir = async (values) => {
  try {
    const req = await api.post(`/ChatConversa/Transferir`, values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//SysEsteira
export const SysEsteiraGet = async (filters) => {
  try {
    const req = await api.post(`/SysEsteira/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysEsteiraGetById = async (Id) => {
  try {
    const req = await api.get(`/SysEsteira/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const SysEsteiraAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/SysEsteira/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//CRM
export const CrmNegocioGetByEsteira = async (esteiraId) => {
  try {
    const req = await api.get(`/CrmNegocio/GetByEsteira?id=${esteiraId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioMovEtapa = async (negocioId, etapaId) => {
  try {
    const req = await api.get(`/CrmNegocio/MovEtapa?negocioId=${negocioId}&etapaId=${etapaId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioGet = async (filters) => {
  try {
    const req = await api.post(`/CrmNegocio/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioGetById = async (Id) => {
  try {
    const req = await api.get(`/CrmNegocio/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/CrmNegocio/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioAddOrUpObs = async (values) => {
  try {
    const req = await api.post('/CrmNegocio/AddOrUpdateObservacao', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioGetObsForTimeLine = async (Id) => {
  try {
    const req = await api.get(`/CrmNegocio/GetObsForTimeLine?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioUpdateCustomFields = async (values) => {
  try {
    const req = await api.post('/CrmNegocio/UpdateCustomFields', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Participante
export const ParticipanteGet = async (filters) => {
  try {
    const req = await api.post(`/Participante/GetByFilter`, filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteGetById = async (Id) => {
  try {
    const req = await api.get(`/Participante/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ParticipanteAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Participante/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Projetos
export const ProjetoAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Projeto/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProjetoGet = async (filters) => {
  try {
    const req = await api.post('/Projeto/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProjetoGetById = async (Id) => {
  try {
    const req = await api.get(`/Projeto/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ProjetoGetDetails = async (Id) => {
  try {
    const req = await api.get(`/Projeto/GetDetails?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Tarefas
export const TarefaAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Tarefa/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TarefaGet = async (filters) => {
  try {
    const req = await api.post('/Tarefa/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TarefaGetById = async (Id) => {
  try {
    const req = await api.get(`/Tarefa/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TarAponGetTimeSheet = async (filters) => {
  try {
    const req = await api.post('/Tarefa/TarAponGetTimeSheet', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Apontamentos
export const TarefaTarAponAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Tarefa/TarAponAddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TarefaTarAponGetByTarefa = async (Id) => {
  try {
    const req = await api.get(`/Tarefa/TarAponGetByTarefa?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const TarefaTarAponDel = async (Id) => {
  try {
    const req = await api.delete(`/Tarefa/TarAponDel?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Dashboard
export const CrmNegocioGetChartTpPerda = async (filters) => {
  try {
    const req = await api.post('/CrmNegocio/GetChartTpPerda', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CrmNegocioGetChartPorStatus = async (filters) => {
  try {
    const req = await api.post('/CrmNegocio/GetChartPorStatus', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const ChatConversaGetChartPorStatus = async (filters) => {
  try {
    const req = await api.post('/ChatConversa/GetChartPorStatus', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const CampanhaGetChartUtms = async (filters) => {
  try {
    const req = await api.post('/Campanha/GetChartUtms', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

//Patrimonio
export const PatrimonioGetById = async (Id) => {
  try {
    const req = await api.get(`/Patrimonio/GetById?id=${Id}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PatrimonioAddOrUpdate = async (values) => {
  try {
    const req = await api.post('/Patrimonio/AddOrUpdate', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PatrimonioGet = async (filters) => {
  try {
    const req = await api.post('/Patrimonio/GetByFilter', filters)
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PatrimonioGetPatGrupo = async () => {
  try {
    const req = await api.get('/Patrimonio/PatGrupoGetAll')
    return req.data
  } catch (error) {
    return undefined
  }
}

export const PatrimonioGetPatHistorico = async (patrimonioId) => {
  try {
    const req = await api.get(`/Patrimonio/PatHistoricoGet?id=${patrimonioId}`)
    return req.data
  } catch (error) {
    return undefined
  }
}
export const PatrimonioAddPatHistorico = async (values) => {
  try {
    const req = await api.post('/Patrimonio/PatHistoricoAdd', values)
    return req.data
  } catch (error) {
    return undefined
  }
}
